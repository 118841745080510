<template>
  <div class="ui-px-6">
    <div class="ui-py-8 c-text-h2 ui-text-left" data-cy="lista-de-faixas">Lista de Faixas</div>
    <div class="c-row ui-mb-8 flex-centered">
      <div class="c-col ui-flex-grow">
        <c-search-bar
          placeholder="Busque por título da faixa"
          v-model="filter"
        />
      </div>
      <div class="c-col ui-flex-grow-0">
        <c-button color="primary" class="add-button" @click="createTrack">
          Criar nova faixa
          <icon name="add" class="c-icon--right" />
        </c-button>
      </div>
    </div>

    <div class="c-row">
      <div class="c-col">
        <c-table class="ui-w-full">
          <template slot="head">
            <c-table-row>
              <c-table-head class="ui-pr-2"> Título da Faixa </c-table-head>
              <c-table-head> Criado em </c-table-head>
              <c-table-head class="no-wrap"> Atualizado em </c-table-head>
              <c-table-head> Professor </c-table-head>
              <c-table-head> Classificações </c-table-head>
              <c-table-head> Duração </c-table-head>
              <c-table-head class="ui-pr-2"> Ações </c-table-head>
            </c-table-row>
          </template>

          <c-table-row v-for="track in tracks" :key="track.id">
            <c-table-data>
              <div class="ui-flex flex-centered flex-gap-10" v-show="!loading">
                <c-button
                  outline
                  color="gray"
                  class="
                    ui-border-2 ui-rounded-full
                    c-border-gray-400
                    ui-p-1 ui-h-auto
                    c-button--dark-gray c-button--center
                  "
                  @click="stopTrack()"
                  v-if="track.id === trackPlayingId"
                >
                  <icon
                    name="pause"
                    width="20"
                    height="20"
                    class="c-icon--center"
                    color="var(--c-color-gray-400)"
                  />
                </c-button>
                <c-button
                  outline
                  color="gray"
                  class="
                    ui-border-2 ui-rounded-full
                    c-border-gray-400
                    ui-p-1 ui-h-auto
                    c-button--dark-gray c-button--center
                  "
                  @click="playTrack(track.id)"
                  v-if="track.id !== trackPlayingId"
                >
                  <icon
                    name="play"
                    width="20"
                    height="20"
                    class="c-icon--center"
                    color="var(--c-color-gray-400)"
                  />
                </c-button>
                <div>{{ track.title }}</div>
              </div>
              <c-skeleton-block v-show="loading" />
            </c-table-data>

            <c-table-data
              v-for="column in tableColumns"
              :key="track.id + '-' + column"
            >
              <span v-show="!loading">{{ track[column] }}</span>
              <c-skeleton-block v-show="loading" />
            </c-table-data>

            <c-table-data align="right">
              <div class="ui-flex" v-show="!loading">
                <c-link flat color="primary" @click="editTrack(track.id)">
                  <icon
                    name="edit"
                    outline
                    class="c-icon--right"
                    color="primary"
                  />
                </c-link>
                <c-link flat color="red" @click="openDeleteTrackDialog(track)">
                  <icon
                    name="trash"
                    height="20px"
                    outline
                    class="c-icon--right"
                    color="red"
                  />
                </c-link>
              </div>
              <c-skeleton-block v-show="loading" />
            </c-table-data>
          </c-table-row>
        </c-table>
      </div>
    </div>

    <pagination
      :page="pagination.page"
      :total-pages="Math.ceil(pagination.total / pagination.perPage)"
      :rowsPerPage="pagination.perPage"
      @changePage="changePage"
      @changePerPage="changePerPage"
    />

    <modal :show="deleteDialog" @close="deleteDialog = false">
      <template v-slot:title>Deseja remover esta faixa?</template>
      <template v-slot:description>
        <p>{{ selectedTrack.title }}</p>
      </template>
      <template v-slot:actions>
        <div class="ui-flex">
          <div class="ui-flex-1">
            <c-link
              class="ui-w-full ui-h-full c-text-gray-300"
              component="button"
              @click="deleteDialog = false"
              >Não, cancelar</c-link
            >
          </div>
          <div class="ui-flex-1">
            <c-button class="ui-w-full" color="primary" @click="deleteTrack()"
              >Sim, remover</c-button
            >
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<style scoped>
.flex-centered {
  display: flex;
  align-items: center;
}
.flex-gap-10 {
  gap: 10px;
}
.add-button {
  width: 229px;
}
.no-wrap {
  white-space: nowrap;
}
</style>

<script>
import {
  CSearchBar,
  CButton,
  CLink,
  CTable,
  CTableRow,
  CTableHead,
  CTableData,
  CSkeletonBlock,
  Icon,
} from "@estrategiahq/coruja-web-ui";
import Modal from "../../components/Modal.vue";
import Pagination from "../../components/Pagination.vue";

const loadingItems = Array.from({ length: 20 }, () => ({}));

const prettyDuration = (value) => {
  if (!value) return "";
  return new Date(1000 * parseInt (value)).toISOString().substr(11, 8)
};

const joinList = (list, attribute, maxElements) => {
  if (list.length > maxElements) {
    return (
      list
        .slice(0, maxElements)
        .map((i) => i[attribute])
        .join(", ") + "..."
    );
  }
  return list.map((i) => i[attribute]).join(", ");
};

export default {
  components: {
    Modal,
    Pagination,
    CSearchBar,
    CButton,
    CLink,
    CTable,
    CTableRow,
    CTableHead,
    CTableData,
    CSkeletonBlock,
    Icon,
  },
  data() {
    return {
      tracks: loadingItems,
      tableColumns: [
        "created_at",
        "updated_at",
        "formatedTeachers",
        "formatedSubjects",
        "formatedDuration",
      ],
      pagination: {
        page: 1,
        perPage: 20,
        total: 0,
      },
      filter: "",
      loading: false,
      deleteDialog: false,
      selectedTrack: {
        id: 0,
      },
      player: null,
      trackPlayingId: null,
      timeout: null,
    };
  },
  mounted() {
    this.fetchTracks({
      pagination: this.pagination,
      filter: this.filter,
    });
    this.mountPlayer();
  },
  beforeDestroy() {
    this.player.pause();
    this.player = null;
  },

  watch: {
    async filter(newFilter) {
      newFilter = newFilter.trim();
      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.fetchTracks({
          pagination: this.pagination,
          filter: newFilter,
          sortBy: this.sortBy,
        });
      }, 300);
    },
  },
  methods: {
    async fetchTracks({ pagination, filter }) {
      this.loading = true;

      let search = `?page=${pagination.page}&per_page=${pagination.perPage}`;
      search += !filter.length ? "" : `&search=(title[icontains]:'${filter}')`;

      let result;
      result = await this.$s.track.fetchTracks(search);
      this.tracks = result.data;
      this.pagination.page = result.meta.page;
      this.pagination.perPage = result.meta.per_page;
      this.pagination.total = result.meta.total;

      this.formatOutput();
      this.loading = false;
    },
    formatOutput() {
      this.tracks = this.tracks.map((item) => ({
        ...item,
        created_at: Intl.DateTimeFormat("pt-br", { dateStyle: "short" }).format(
          Date.parse(item.created_at)
        ),
        updated_at: Intl.DateTimeFormat("pt-br", { dateStyle: "short" }).format(
          Date.parse(item.created_at) > Date.parse(item.updated_at)
            ? Date.parse(item.created_at)
            : Date.parse(item.updated_at)
        ),
        formatedTeachers: joinList(item.teachers, "name", 3),
        formatedSubjects: item.subjects
          ? joinList(item.subjects, "title", 3)
          : item.subject.name,
        formatedDuration: prettyDuration(item.duration),
      }));
    },
    createTrack() {
      this.$router.push({
        name: "createTrack",
      });
    },
    editTrack(id) {
      this.$router.push({
        name: "editTrack",
        params: { id, edit: true },
      });
    },
    openDeleteTrackDialog(track) {
      this.deleteDialog = true;
      this.selectedTrack = track;
    },
    async deleteTrack() {
      try {
        await this.$s.track.deleteTrack(this.selectedTrack.id);
        this.$emit("toast", {
          type: "success",
          text: "Faixa removida com sucesso!",
          timeout: 3000,
          position: "top",
        });
      } catch (error) {
        this.$emit("toast", {
          type: "error",
          text: "Erro ao remover faixa. Tente novamente.",
          timeout: 3000,
          position: "top",
        });
      }
      this.selectedTrack = {};
      this.deleteDialog = false;
      await this.fetchTracks({
        pagination: this.pagination,
        filter: this.filter,
      });
    },
    mountPlayer() {
      const audio = new Audio();
      audio.autoplay = true;
      this.player = audio;
    },
    async playTrack(id) {
      this.trackPlayingId = id;
      let result = await this.$s.track.getTrackUrl(id);
      const trackUrl = result.data.url;
      this.player.src = trackUrl;
    },
    stopTrack() {
      this.trackPlayingId = null;
      this.player.pause();
      this.player.src = null;
    },
    async changePage(page) {
      this.pagination.page = page;
      await this.fetchTracks({
        pagination: this.pagination,
        filter: this.filter,
      });
    },
    async changePerPage(perPage) {
      this.pagination = {
        page: 1,
        perPage: perPage,
      };
      await this.fetchTracks({
        pagination: this.pagination,
        filter: this.filter,
      });
    },
  },
};
</script>
